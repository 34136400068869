import React from "react";
import useLocale from "../hooks/useLocale";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import useTranslation from "../hooks/useTranslations";
import { colors } from "../style/theme";
import style from "../style/leaflet.css";
import { isNil } from "ramda";

export default function Map({ incidents, viewport, cssClass = "leaflet-map" }) {
  const { isAra } = useLocale();
  const tr = useTranslation();
  const mapState = {
    attribution: tr(
      "For security reasons, the location coordinates used for maps in this database and report are approximated and intentionally, slightly inaccurate."
    ),
    tile: "https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png",
  };

  if (isNil(viewport)) return <div />;

  return (
    <MapContainer
      center={[viewport?.lat, viewport?.lng]}
      zoom={viewport?.zoom}
      scrollWheelZoom={false}
      className={cssClass}
    >
      <TileLayer attribution={mapState.attribution} url={mapState.tile} />
      {incidents.map((node) => {
        return (
          <Marker
            key={node.id}
            position={{
              lat: Number(node.lat),
              lng: Number(node.long),
            }}
            riseOnHover={true}
            opacity="0"
          >
            <Popup>
              <span>{isAra ? node.hospital_ar : node.hospital_en}</span>
            </Popup>
            <Circle
              center={{
                lat: Number(node.lat),
                lng: Number(node.long),
              }}
              fill={true}
              stroke={false}
              opacity={0}
              fillColor={colors.primary}
              radius={viewport?.radius || 5000}
              fillOpacity={0.7}
            />
          </Marker>
        );
      })}
    </MapContainer>
  );
}
